
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref,
  onMounted,
  computed
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { min } from "lodash";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const grids = ref<any[]>([]);

    const cols = ref<any[]>([{
      field: "time",
      title: "時段",
      showHeaderOverflow: true,
      showOverflow: true,
      sortable: true,
      minWidth: "100"
    }]);
    const gridOptions = ref<GridOptions>();
    // const gridOptions: GridOptions = {
    //   stripe: false,
    //   title: "車輛",
    //   multiselect: false,
    //   canUpdate: false,
    //   canDelete: false,
    //   canRead: false,
    //   canCreate: false,
    //   toolbarConfig: {
    //     custom: true,
    //     refresh: true
    //   },
    //   exportConfig: {
    //     filename: "車輛清單",
    //     type: "csv",
    //     types: ["html", "csv"],
    //     mode: "all",
    //     modes: ["current", "selected", "all"],
    //     // columns: printColumns
    //   },
    //   columns: cols.value,
    //   promises: {
    //     query: model
    //       ? async (params) => {
    //         var result = await model.dispatch("order/statistics", params);
    //         console.log(result);
    //         return result;
    //       }
    //       : undefined,
    //     queryAll: model ? () => model.dispatch("order/statistics") : undefined,
    //     save: model ? params => model.dispatch("bike/save", params) : undefined
    //   },
    //   modalConfig: { showFooter: true, width: "60%" }
    //   //   treeConfig: { children: "Children" }
    // };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Number",
          title: "車輛編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "ProductId",
          title: "歸屬商品",
          span: 12,
          titleWidth: 100,
          slots: { default: "column-product-id" }
        },
        // {
        //   field: "Published",
        //   title: "已發佈",
        //   span: 12,
        //   titleWidth: 100,
        //   itemRender: {
        //     name: "$select",
        //     options: [
        //       { value: false, label: "否" },
        //       { value: true, label: "是" }
        //     ]
        //   }
        // },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          titleWidth: 100,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入備註" }
          }
        }
      ],
      rules: {
        Number: [{ type: "string", required: true }],
        ProductId: [{ type: "string", required: true }]
      }
    };

    const productIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇車型",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => {
          params.condition!.and("Type", Operator.Equal, 0);
          return model!.dispatch("product/query", params); // eslint-disable-line
        }
      }
    };

    var getColumns = (data: any) => {
      var cols = [] as any;
      if (data && data.length) {
        var result = data[0];
        for (const key in result) {
          cols.push({
            field: key == 'Hour' ? key : `${key}.Count`,
            title: key == 'Hour' ? "時段" : result[key].Name,
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            minWidth: "100"
          })
        }
      }
      // var cols = [
      //   {
      //     field: "Hour",
      //     title: "時段",
      //     showHeaderOverflow: true,
      //     showOverflow: true,
      //     sortable: true,
      //     minWidth: "100"
      //   },
      // {
      //   field: "people",
      //   title: "預計取車人數",
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   minWidth: "200"
      // },
      // {
      //   field: "single",
      //   title: "單人車",
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   minWidth: "100"
      // },
      // {
      //   field: "double",
      //   title: "雙人車",
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   minWidth: "100"
      // },
      // {
      //   field: "electric",
      //   title: "電動機車",
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   minWidth: "100"
      // },
      // {
      //   field: "raincoat",
      //   title: "雨衣",
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   minWidth: "100"
      // }
      //];
      return cols;
    }

    var getOptions = (data: any) => {
      var cols = getColumns(data);

      const gridOptions: GridOptions = {
        stripe: false,
        title: "車輛",
        multiselect: false,
        canUpdate: false,
        canDelete: false,
        canRead: false,
        canCreate: false,
        toolbarConfig: {
          custom: true,
          refresh: true
        },
        exportConfig: {
          filename: "車輛清單",
          type: "csv",
          types: ["html", "csv"],
          mode: "all",
          modes: ["current", "selected", "all"],
          // columns: printColumns
        },
        columns: cols,
        promises: {
          query: (params: any) => {
            return new Promise((resolve, reject) => {
              var result = data ?? [];
              var current = result.slice((params.page - 1) * params.pageSize, params.page * params.pageSize);
              setTimeout(() => {
                resolve({
                  data: current,
                  totalCount: result.length,
                });
              }, 100);
            });
          },
        },
        modalConfig: { showFooter: true, width: "60%" }
      };
      return gridOptions;
    }

    const loadData = async () => {
      var condition = new Condition().and("DataMode", Operator.Equal, 0).and("Status", Operator.NotEqual, 2);
      if (station.value) {
        //condition.and("StationId", Operator.IsNotNull);
        condition.and("StationId", Operator.Equal, station.value);
      }
      if (area.value) {
        condition.and("Station.AreaId", Operator.Equal, area.value);
      }
      if (daterange.value) {
        var date = daterange.value.toString().split(' - ');
        var startDate = date?.[0] + ' 00:00:00';
        var endDate = date?.[1] + ' 23:59:59';
        var start = new Condition().and("BookingTime", Operator.IsNull).or("BookingTime", Operator.GreaterThanOrEqual, new Date(startDate));
        var end = new Condition().and("BookingTime", Operator.IsNull).or("BookingTime", Operator.LessThanOrEqual, new Date(endDate));

        condition.and(start).and(end);
      }

      data.value = await model!.dispatch('order/statistics', { keyword: null, condition: condition, page: 1 });
      grids.value = [] as any;
      if (data.value && (data.value as any).length) {
        (data.value as any).forEach((e: any) => {
          (grids.value as any[]).push({
            Date: e.Date,
            grid: null,
            gridOption: getOptions(e.Data),
          });
        })
      }
    }


    const daterange = ref<any>();
    const stations = ref<any>([]);
    const station = ref<any>(null);
    const areas = ref<any>([]);
    const area = ref<any>(null);
    const data = ref({});
    onMounted(async () => {
      var condition = new Condition("Published", Operator.Equal, true);
      areas.value = await model!.dispatch('area/options', { keyword: null, condition: condition });
      stations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
      await loadData();
      // setTimeout(() => {
      //   (grid.value as any).reloadColumn(cols.value);
      // }, 150);
    });

    const areaChange = async () => {
      station.value = null;
      const condition = new Condition("Published", Operator.Equal, true);
      if (area.value) {
        condition.and("AreaId", Operator.Equal, area.value);
      }
      stations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
    }

    const calendarApply = "加入";
    const calendarCancel = "取消";
    const calendarFormat = "YYYY-MM-DD";
    const calendarLang = "zh-TW";

    const title = computed(() => {
      var areaString = area.value ? areas.value.find((e: any) => area.value == e.value)?.label : null;
      var stationString = station.value ? stations.value.find((e: any) => station.value == e.value)?.label : null;
      return `${areaString ?? ''} ${stationString ? areaString ? `- ${stationString}` : stationString : ''} ${daterange.value ? daterange.value.replace(' - ', ' ~ ') : ''} 預估車輛查詢`;
    })

    return {
      grid,
      gridOptions,
      formOptions,
      productIdSelectOptions,
      calendarApply,
      calendarCancel,
      calendarFormat,
      calendarLang,
      cols,
      stations,
      station,
      area,
      areas,
      areaChange,
      loadData,
      daterange,
      title,
      grids,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridCellClick(date: string, row: any) {
      const hour = parseInt(row.Hour.replace(":00", ""));
      this.$router.push({
        path: "/order",
        query: {
          startTime: `${date} ${hour}:00`,
          endTime: `${date} ${hour + 1}:00`,
        }
      });
    }
  }
});
